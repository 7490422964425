/* eslint eqeqeq: "off" */
import React, { useState } from "react"
import connectStore from "../state/connectStore"
import { getDryToppingNames, getImage, isFlowing } from "../util/OrderUtil"
import InventoryGrid, { InventoryPalette } from "../ui/InventoryGrid"
import {
  Divider,
  Container,
} from "semantic-ui-react"

const COLS = 8;
const ROWS = 5;
const ITEMS = getDryToppingNames();

const DryToppingInventory = ({ inventory, setInventoryDryTopping, setFlowing, restock=false }) => {
  const { dryToppings } = inventory;
  const [selectedTopping, setSelectedTopping] = useState(null);

  const isItemFlowing = item => {
    return isFlowing(inventory, "dryToppings", item);
  };

  const setItemFlowing = (item, flowing) => {
    setFlowing({ type: "dryToppings", name: item, flowing });
  };

  const onIndexClick = index => {
    if(dryToppings[index] != selectedTopping && restock) {
      setInventoryDryTopping({ name: selectedTopping, index });
    }
  };

  return (
    <div>
      <div className="super-centered large-margin-top">
        <InventoryPalette
          items={ITEMS}
          fromItemToImage={getImage}
          selectedItem={selectedTopping}
          setSelectedItem={setSelectedTopping}
          showNames={true}
          readOnly={!restock}
          setItemFlowing={setItemFlowing}
          isItemFlowing={isItemFlowing}
        />
      </div>
      <Container textAlign="center">
        <Divider hidden className="half-divider"/>
        <InventoryGrid
          rowCount={ROWS}
          colCount={COLS}
          items={dryToppings}
          fromItemToImage={getImage}
          onIndexClick={onIndexClick}
          scroll
          smaller
        />
      </Container>
    </div>
  );
};

export default connectStore(DryToppingInventory);
