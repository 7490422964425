/* eslint eqeqeq: "off" */
import React, { useState, useEffect } from "react"
import { withAuthenticator } from "@aws-amplify/ui-react"
import Layout from "../ui/Layout"
import connectStore from "../state/connectStore"
import DryToppingInventory from "../ui/DryToppingInventory"
import WetToppingInventory from "../ui/WetToppingInventory"
import CookieInventory from "../ui/CookieInventory"
import { fetchInventory, submitInventory, requestEntry, WET_TOPPING_LIMIT } from "../util/OrderUtil"
import {
  Tab,
  Step,
  Icon,
  Button,
  Menu,
  Label,
  Modal,
  Segment,
  Popup,
  List,
  Header,
} from "semantic-ui-react"
import Color from "../style/Color"



const TAB_COOKIE = "Update Cookies";
const TAB_DRY = "Update Dry Toppings";
const TAB_WET = "Update Wet Toppings";
const TAB_SUBMIT = "Submit";

const STEPS = [TAB_COOKIE, TAB_DRY, TAB_WET, TAB_SUBMIT];

const toNextTab = tab => {
  const index = STEPS.indexOf(tab) + 1;
  if(index >= STEPS.length) {
    return null;
  }
  return STEPS[index];
};

const toPrevTab = tab => {
  const index = STEPS.indexOf(tab) - 1;
  if(index < 0) {
    return null;
  }
  return STEPS[index];
};

const Warn = ({ emptyCount, totalCount, right=false }) => {
  const perc = (totalCount-emptyCount) / totalCount;
  let color = "";
  if(perc > 0.50) {
    color = "green";
  } else if(perc > 0.25) {
    color = "yellow";
  } else {
    color = "red";
  }
  return <Label color={color} floating className={right ? "shifted-right" : "shifted-left"}>{(totalCount-emptyCount) + "/" + totalCount}</Label>
};

const Spacer = props => <div className="spacer"/>;

const Footer = ({ restock, setRestock, tab, setTab, setInventory }) => {
  const [entryStatus, setEntryStatus] = useState(null);
  
  return (
    <div>
      <Modal
        size="mini"
        open={entryStatus != null}
        header={entryStatus == "entry requested" ? 
          "Request to Enter Success!"
          :
          "Request to Enter Failure!"
        }
        content={entryStatus == "entry requested" ? 
          "Your request to enter was sent successfully. Please wait for the robot to open the door." 
          : 
          "Your request to enter was not sent. Please try again."
        }
        actions={[
          { key: "ok", content: "Ok", positive: false, onClick: () => {
            setEntryStatus(null);
          }}
        ]}
      />
      
      <Modal
        size="mini"
        trigger={
          <Button
            className="start-stop"
            content="Request to Enter"
            color={Color.accent}
            icon="unlock"
            labelPosition="right"
            size="large"
          />
        }
        header="Confirm Request to Enter"
        content="You are about to stop the robot. This will hault all orders currently in progress. Are you sure?"
        actions={[
          { key: "no", content: "No", positive: false },
          { key: "yes", content: "Yes", positive: true, onClick: () => {
            requestEntry(({ status="not requested" }) => {
              setEntryStatus(status);
            });
          }}
        ]}
      />

      <div className="start-stop info">
        <Popup
          trigger={<Button size="small" compact circular icon="info"/>}
          on="click"
          content={
            <>
              <Header>About Request to Enter</Header>
              <List>
                <List.Item><List.Header>Opening the Kiosk</List.Header>Due to robot safety best practices, the kiosk is locked from the inside and the robot must be notified when you wish to enter.</List.Item>
                <List.Item>Clicking "Request to Enter" will notify the robot that you need to open the kiosk. It will finish its current task and then unlock the kiosk from the inside.</List.Item>
                <List.Item>Once the robot has unlocked the kiosk and stopped moving, it is safe to open the kiosk door.</List.Item>
                <List.Item>While one request should be adequate, if you are unsure, it is safe to request to enter multiple times in a row if you feel the robot ignored your initial request.</List.Item>
                <List.Item><List.Header>Closing the Kiosk</List.Header>To close the kiosk, simply shut the door. The robot will start back up automatically and pickup up from where it left off.</List.Item>
              </List>
            </>
          }
          />
      </div>
      { !restock && <div>
        <div className="spacer">
          <Popup
            trigger={<Button size="small" compact circular icon="info"/>}
            on="click"
            content={
              <>
                <Header>About Restocking</Header>
                <List>
                  <List.Item><List.Header>Start Restocking</List.Header>To restock the inventory, click "Start Restocking."</List.Item>
                  <List.Item><List.Header>Making Changes</List.Header>Changes can only be made to the inventory once "Start Restocking" has been clicked. You will need to go page by page by clicking the "Next" button, first restocking cookies, then dry toppings, and finally, wet toppings.</List.Item>
                  <List.Item>For instructions on stocking individual ingredients, click the info box next to "ingredients" in the top table.</List.Item>
                  <List.Item><List.Header>Submitting Changes</List.Header>Changes will not be applied until "Submit" is clicked on the final page (wet toppings). Once submitted, the robot will become immediately aware of the new inventory state.</List.Item>
                  <List.Item><List.Header>Canceling Changes</List.Header>Clicking "Cancel" at any time will undo any changes to the inventory and return you to the original, "read only" inventory.</List.Item>
                </List>
              </>
            }
            />
        </div>
        <Modal
          size="mini"
          trigger={
            <Button
              content={"Start Restocking"}
              color={Color.accent}
              icon="angle right"
              labelPosition="right"
              size="huge"
            />
          }
          header="Confirm Start Restocking"
          content="You are about to start restocking. Are you sure?"
          actions={[
            { key: "no", content: "No", positive: false },
            { key: "yes", content: "Yes", positive: true, onClick: () => {
              setTab(TAB_COOKIE);
              setRestock(true);
            }}
          ]}
        />
      </div> }
      { restock && <div className="inline-block">
        <Modal
          size="mini"
          trigger={<Button
            content={"Cancel"}
            color={Color.accent}
            icon="close"
          />}
          header="Confirm Cancel"
          content="Are you sure you want to cancel restocking the inventory? All changes will be discarded."
          actions={[
            { key: "no", content: "No", positive: false },
            { key: "yes", content: "Yes", positive: true, onClick: () => {
              fetchInventory(inv => {
                setInventory(inv);
                setRestock(false);
              });
            }}
          ]}
        />
        <Spacer/>
      </div> }
      { restock && tab != TAB_COOKIE && <div className="inline-block">
        <Button
          content={"Prev"}
          onClick={() => setTab(toPrevTab(tab))}
          color={Color.accent}
          icon="angle left"
        />
        <Spacer/>
      </div> }
      { restock &&
        <Button
          content={tab == TAB_SUBMIT || tab == TAB_WET ? "Submit" : "Next"}
          onClick={() => {
            const t = toNextTab(tab);
            if(t == null) {
              setTab(TAB_COOKIE);
              setRestock(false);
            } else {
              setTab(t);
            }
          }}
          color={Color.accent}
          icon="angle right"
          labelPosition="right"
          size="huge"
        />
      }
    </div>
  );
};

const Content = ({ inventory, restock, setRestock, tab, setTab, setInventory }) => {
  const { cookies, dryToppings, wetToppingCounts } = inventory;

  const cookieEmptyCount = cookies.filter(c => c == null).length;
  const dryToppingEmptyCount = dryToppings.filter(c => c == null).length;

  const cookieWarnings = cookieEmptyCount;
  const dryWarnings = dryToppingEmptyCount;
  const wetWarnings = wetToppingCounts.map(c => WET_TOPPING_LIMIT - c);

  return (
    <div>
      { restock &&
      <div>
        <Step.Group size="mini" as={Menu} ordered>
          <Step active={tab == TAB_COOKIE}>
            <Icon name="database"/>
            <Step.Content>
              <Step.Title>Cookies</Step.Title>
              <Step.Description>Restock</Step.Description>
            </Step.Content>
            <Spacer/>
            <Warn emptyCount={cookieWarnings} totalCount={cookies.length}/>
          </Step>
          <Step active={tab == TAB_DRY}>
            <Icon name="cubes"/>
            <Step.Content>
              <Step.Title>Dry Toppings</Step.Title>
              <Step.Description>Restock</Step.Description>
            </Step.Content>
            <Spacer/>
            <Warn emptyCount={dryWarnings} totalCount={dryToppings.length}/>
          </Step>
          <Step active={tab == TAB_WET}>
            <Icon name="tint"/>
            <Step.Content>
              <Step.Title>Wet Toppings</Step.Title>
              <Step.Description>Restock</Step.Description>
            </Step.Content>
            <Spacer/>
            { wetWarnings.map((w, i) => <Warn key={i} emptyCount={w} totalCount={WET_TOPPING_LIMIT} right={i > 0}/>) }
          </Step>
        </Step.Group>
        { tab == TAB_COOKIE && <CookieInventory restock={restock}/> }
        { tab == TAB_DRY && <DryToppingInventory restock={restock}/> }
        { tab == TAB_WET && <WetToppingInventory restock={restock}/> }
        { tab == TAB_SUBMIT &&
          <Modal
            open={true}
            size="mini"
            header="Confirm Submit"
            content="Are you sure you want to submit this inventory? All changes will be sent to the kiosk. This cannot be undone."
            actions={[
              { key: "no", content: "No, Go Back", positive: false, onClick: () => setTab(TAB_WET) },
              { key: "yes", content: "Yes", positive: true, onClick: () => {
                submitInventory(inventory, () => {
                  // Still grab the latest copy just in case our restock 
                  // didn't go through
                  fetchInventory(inv => {
                    setInventory(inv);
                  });

                  // Finish the restocking process regardless
                  setRestock(false);
                });
              }}
            ]}
          />
        }
      </div> }
      {!restock &&
      <Tab menu={{ attached: false, compact: true, inverted: true }} panes={[
        { menuItem: (
          <Menu.Item key="cookies">
            <Icon name="database"/>Cookies<Warn emptyCount={cookieWarnings} totalCount={cookies.length}/>
          </Menu.Item>
        ), render: () => (
          <CookieInventory/>
        )},
        { menuItem: (
          <Menu.Item key="dry-topppings">
            <Icon name="cubes"/>Dry Toppings<Warn emptyCount={dryWarnings} totalCount={dryToppings.length}/>
          </Menu.Item>
        ), render: () => (
          <DryToppingInventory/>
        )},
        { menuItem: (
          <Menu.Item key="wet-toppings">
            <Icon name="tint"/>Wet Toppings{ wetWarnings.map((w,i) => <Warn key={i} emptyCount={w} totalCount={WET_TOPPING_LIMIT} right={i > 0}/>) }
          </Menu.Item>
        ), render: () => (
          <WetToppingInventory/>
        )},
      ]}/>}
    </div>
  );
}

const Page = ({ location, inventory, setInventory }) => {
  const [restock, setRestock] = useState(false);
  const [tab, setTab] = useState(TAB_COOKIE);
  const [fetching, setFetching] = useState(true);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if(!restock) {
      if(!fetched) {
        fetchInventory(inv => {
          setInventory(inv);
          setFetching(false);
          setFetched(true);
        });
      } else {
        const timer = setTimeout(() => {
          fetchInventory(inv => {
            setInventory(inv);
            setFetching(false);
          });
        }, 30 * 1000);
        return () => clearTimeout(timer);
      }
    }
  }, [restock, fetching, fetched, setInventory]);

  return (
    <Layout location={location} to="/order-complete" showBack={false} validate={false}
      footer={
        <Footer
          restock={restock}
          setRestock={setRestock}
          tab={tab}
          setTab={setTab}
          setInventory={setInventory}
        />
      }
    >
      <Segment vertical loading={fetching} className="border-none">
        <Content
          inventory={inventory}
          restock={restock}
          setRestock={setRestock}
          tab={tab}
          setTab={setTab}
          setInventory={setInventory}
        />
      </Segment>
    </Layout>
  );
};

export default withAuthenticator(connectStore(Page));
