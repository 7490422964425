/* eslint eqeqeq: "off" */
import React, { useState } from "react"
import connectStore from "../state/connectStore"
import { getWetToppingNames, getImage, isFlowing, WET_TOPPING_LIMIT } from "../util/OrderUtil"
import { InventoryPalette } from "../ui/InventoryGrid"
import InventoryCard from "../ui/InventoryCard"
import {
  Divider,
  Container,
  Statistic,
  Button,
  Label,
} from "semantic-ui-react"
import Color from "../style/Color"

const ITEMS = getWetToppingNames();

const WetToppingInventory = ({ inventory, setInventoryWetTopping, setFlowing, setInventoryWetToppingCounts, restock=false }) => {
  const { wetToppings, wetToppingCounts } = inventory;
  const [selectedTopping, setSelectedTopping] = useState(null);

  const isItemFlowing = item => {
    return isFlowing(inventory, "wetToppings", item);
  };

  const setItemFlowing = (item, flowing) => {
    setFlowing({ type: "wetToppings", name: item, flowing });
  };

  const onIndexClick = index => {
    if(restock) {
      if(wetToppings[index] != selectedTopping) {
        setInventoryWetTopping({ name: selectedTopping, index });
        setInventoryWetToppingCounts({ count: WET_TOPPING_LIMIT, index });
      }
    }
  };

  const onIndexReset = index => {
    if(restock) {
      setInventoryWetToppingCounts({ count: WET_TOPPING_LIMIT, index });
    }
  };

  return (
    <div>
      <div className="super-centered large-margin-top">
        <InventoryPalette
          items={ITEMS}
          fromItemToImage={getImage}
          selectedItem={selectedTopping}
          setSelectedItem={setSelectedTopping}
          readOnly={!restock}
          setItemFlowing={setItemFlowing}
          isItemFlowing={isItemFlowing}
        />
      </div>
      <Container textAlign="center">
        <Divider hidden className="half-divider"/>
        { wetToppings.map((t, i) => (
          <Statistic key={i} style={{ padding: "2em" }} size="mini">
            <div style={{ zoom: "0.75"}}>
              <InventoryCard
                size="small"
                image={getImage(t)}
                onClick={() => onIndexClick(i)}
                rounded
                index={i}
              />
            </div>
            <Statistic.Value>
              <Label>{wetToppingCounts[i] == null ? "0 / " + WET_TOPPING_LIMIT : wetToppingCounts[i] + " / " + WET_TOPPING_LIMIT}</Label>
            </Statistic.Value>
            <Divider hidden/>
            <Button color={Color.accent} compact onClick={() => onIndexReset(i)}>Reset</Button>
          </Statistic>
        ))}
      </Container>
    </div>
  );
};

export default connectStore(WetToppingInventory);
