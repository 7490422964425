/* eslint eqeqeq: "off" */
import React from "react"
import InventoryCard from "../ui/InventoryCard"
import { getLabel } from "../util/OrderUtil"
import {
  Menu,
  Icon,
  Label,
  Checkbox,
  Table,
  Button,
  Popup,
  List,
  Header,
} from "semantic-ui-react"
import Color from "../style/Color"

const tableStyle = { display: "inline-table" };

export const InventoryPalette = ({ items, fromItemToImage, selectedItem, setSelectedItem, showNames=true, readOnly=false, isItemFlowing=null, setItemFlowing=null, }) => {
  if(readOnly) {
    selectedItem = null;
  }

  return (
    <Menu vertical className="left floated" compact>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Popup
                trigger={<Button size="mini" compact circular icon="info"/>}
                on="click"
                content={
                  <>
                    <Header>About Flowing</Header>
                    <List>
                      <List.Item><List.Header>What does "flowing" mean?</List.Header>When an ingredient is flowing, the robot will assume that you are going to restock it as soon as it gets low.</List.Item>
                      <List.Item><List.Header>Why do we use it?</List.Header>It lets customers order an ingredient even when the robot thinks it's going to run out. Since you'll be restocking the ingredient before that happens, it's your way of telling the robot not to worry.</List.Item>
                      <List.Item><List.Header>When should I use it?</List.Header>As long as you will be be actively restocking this ingredient, you should mark it as "flowing."</List.Item>
                      <List.Item>If you have to leave or cannot restock the ingredient, mark it as "not flowing." Eventually, customers will see it as "out of stock."</List.Item>
                    </List>
                  </>
                }
              />
              Flowing
            </Table.HeaderCell>
            {/* showNames && <Table.HeaderCell>Name</Table.HeaderCell> */} 
            <Table.HeaderCell>
              <Popup
                trigger={<Button size="mini" compact circular icon="info"/>}
                on="click"
                content={
                  <>
                    <Header>About Ingredients</Header>
                    <List>
                      <List.Item><List.Header>Start Restocking</List.Header>To restock any ingredients, you must first click "Start Restocking" below.</List.Item>
                      <List.Item><List.Header>Restock Ingredient</List.Header>To restock an ingredient, click an ingredient icon in this column, then click one or more numbered slots in the table below.</List.Item>
                      <List.Item><List.Header>Unstock Ingredient</List.Header>To unstock an ingredient, click the eraser icon in this column, then click one ore more numbered slots in the table below.</List.Item>
                    </List>
                  </>
                }
              />
              Ingredient
            </Table.HeaderCell>
          </Table.Row>                
        </Table.Header>
        <Table.Body>
          {items.map((item, i) => (
            <Table.Row key={i}>
              <Table.Cell>
                { readOnly ?
                  isItemFlowing != null && isItemFlowing(item) ? <Label color="green" circular content="Yes"/> : <Label color="red" circular content="No"/>
                :
                  <Checkbox toggle color={Color.accent} checked={isItemFlowing != null && isItemFlowing(item)} onChange={(e, { checked }) => setItemFlowing != null && setItemFlowing(item, checked)} />
                }
              </Table.Cell>
              {/* showNames && 
                <Table.Cell>
                  {getLabel(item)}
                </Table.Cell> 
              */}
              <Table.Cell>
                <InventoryCard
                  size="mini"
                  image={fromItemToImage(item)}
                  selected={item == selectedItem}
                  onClick={readOnly ? null : () => item != selectedItem && setSelectedItem(item)}
                  rounded
                />
                <div className="spacer" onClick={readOnly ? null : () => item != selectedItem && setSelectedItem(item)}>{getLabel(item)}</div>
              </Table.Cell>
            </Table.Row>
          ))}
          { !readOnly && 
            <Table.Row key={items.length}>
              <Table.Cell></Table.Cell>
              {/* showNames && 
                <Table.Cell>Erase</Table.Cell>
              */}
              <Table.Cell>
                <Icon.Group>
                    <Icon
                      name="eraser"
                      size="big"
                      onClick={() => setSelectedItem(null)}
                      />
                    {selectedItem == null && <Icon
                      name="square outline"
                      color={Color.accent}
                      size="huge"
                      />}
                  </Icon.Group>
                  <div className="spacer" onClick={() => setSelectedItem(null)}>Erase</div>
              </Table.Cell>
            </Table.Row>
          }
        </Table.Body>
      </Table>
    </Menu>
  );
}

const InventoryColumn = ({ row, col, rowCount, colCount, items, fromItemToImage, onIndexClick, fromItemToLabel=null, stack=1, size="tiny" }) => {
  const index = row*colCount + col + ((stack-1)*rowCount*colCount);
  const item = index >= items.length ? undefined : items[index];
  const label = fromItemToLabel == null ? null : fromItemToLabel(item, index);
  return (
    <Table.Cell key={col} className="text-align-center">
      <InventoryCard
        label={label}
        image={item == null ? undefined : fromItemToImage(item)}
        size={size}
        onClick={() => onIndexClick(index)}
        index={index}
      />
    </Table.Cell>
  );
};

const InventoryGrid = ({ rowCount, colCount, items, fromItemToImage, onIndexClick, smaller=false, scroll=false, fromItemToLabel=null, stack=1, size="tiny" }) => {
  return (
    <Table className={"compact" + (scroll ? " scroll" : "") + (smaller ? " smaller" : "")} style={tableStyle} collapsing compact celled columns={colCount}>
      <Table.Body>
      {[...Array(rowCount)].map((_, row) => (
        <Table.Row key={row}>
          {[...Array(colCount)].map((__, col) => (
            <InventoryColumn
            stack={stack}
            key={col}
            row={row}
            col={col}
            rowCount={rowCount}
            colCount={colCount}
            items={items}
            fromItemToLabel={fromItemToLabel}
            fromItemToImage={fromItemToImage}
            onIndexClick={onIndexClick}
            size={size}
            />
            ))}
        </Table.Row>
      ))}
      </Table.Body>
    </Table>
  );
};

export default InventoryGrid;
