/* eslint eqeqeq: "off" */
import React, { useState } from "react"
import connectStore from "../state/connectStore"
import { getCookieNames, getImage, getLabel, isFlowing } from "../util/OrderUtil"
import InventoryGrid, { InventoryPalette } from "../ui/InventoryGrid"
import {
  Divider,
  Container,
  Label,
} from "semantic-ui-react"

const ROWS = 4;
const ITEMS = getCookieNames();
const COOKIES_PER_GROUP = 5;

const CookieInventory = ({ inventory, setInventoryCookie, setFlowing, restock=false }) => {
  const { cookies } = inventory;
  const [selectedCookie, setSelectedCookie] = useState(null);

  const isItemFlowing = item => {
    return isFlowing(inventory, "cookies", item);
  };

  const setItemFlowing = (item, flowing) => {
    setFlowing({ type: "cookies", name: item, flowing });
  };

  const onIndexClick = index => {
    if(restock) {
      const indices = [];
      for(let i = 0; i < COOKIES_PER_GROUP; i++) {
        indices.push(index*COOKIES_PER_GROUP + i);
      }
      setInventoryCookie({ name: selectedCookie, index: indices });
    }
  };

  const firstCookiesInEachGroup = [];
  for(let i = 0; i < cookies.length; i += COOKIES_PER_GROUP) {
    let found = false;
    for(let j = 0; j < COOKIES_PER_GROUP; j++) {
      const cookie = cookies[i+j];
      if(cookie != null) {
        firstCookiesInEachGroup.push(cookie);
        found = true;
        break;
      }
    }
    if(!found) {
      firstCookiesInEachGroup.push(null);
    }
  }
  
  const fromItemToLabel = (item, index) => {
    const realIndex = index * COOKIES_PER_GROUP;
    let count = 0;
    for(let i = 0; i < COOKIES_PER_GROUP; i++) {
      if(cookies[realIndex + i] != null) {
        count++;
      }
    }
    let color = null;
    if(count == 0) {
      color = "red";
    } else if(count < COOKIES_PER_GROUP) {
      color = "yellow";
    }
    return (
      <div className="text-align-center nowrap">
        {getLabel(item)}
        <br/>
        <Label color={color}>{count + " / " + COOKIES_PER_GROUP}</Label>
      </div>
    );
  };

  return (
    <div>
      <div className="super-centered large-margin-top">
        <InventoryPalette
          items={ITEMS}
          fromItemToImage={getImage}
          selectedItem={selectedCookie}
          setSelectedItem={setSelectedCookie}
          readOnly={!restock}
          setItemFlowing={setItemFlowing}
          isItemFlowing={isItemFlowing}
        />
      </div>
      <Container textAlign="center">
        <Divider hidden className="half-divider" />
        <InventoryGrid
          rowCount={ROWS}
          colCount={2}
          items={firstCookiesInEachGroup}
          fromItemToImage={getImage}
          fromItemToLabel={fromItemToLabel}
          onIndexClick={onIndexClick}
          stack={1}
        />
      </Container>
    </div>
  );
};

export default connectStore(CookieInventory);
