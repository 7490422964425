import React from "react"
import {
  Icon,
  Image,
} from "semantic-ui-react"
import QueriedImage from "./QueriedImage"
import Color from "../style/Color"

const imageCheckedStyle = {
  border: "0.5em solid " + Color.accentStyle,
  boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px, rgba(0, 0, 0, 0.5) 0px 0px 10px 5px inset",
};

const imageUncheckedStyle = {};

const InventoryCard = ({ image=null, label=null, selected=false, onClick=null, size="mini", rounded=false, index=null, }) => {
  return (
    <Icon.Group
      onClick={onClick}
    >
      <Image
        circular={!rounded}
        rounded={rounded}
        size={size}
        style={selected ? imageCheckedStyle : imageUncheckedStyle }
      >
        {image || <QueriedImage name="none"/>}
      </Image>
      { label && 
        <div className="super-centered font-style-normal">
          {label}
        </div>
      }
      {/*selected && <Icon name="paint brush" color={Color.accent} />*/}
      {index != null && <div className={size + " inventory-index-label"}>{index+1}</div>}
    </Icon.Group>
  );
};

export default InventoryCard;
